import React from "react";
import "twin.macro";

const PricesBlock = ({ title, headlines, fills }) => {
  return (
    <div tw="p-6 w-full md:w-1/2">
      <div tw="text-2xl">{title}:</div>
      <div tw="grid grid-cols-3">
        {headlines.map((headline) => {
          return <div tw="text-one">{headline}</div>;
        })}
        {fills.map((fill) => {
          return (
            <>
              <div>{fill.name}</div>
              <div>{fill.price}</div>
              <div>{fill.time}</div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default PricesBlock;
