import React from "react";
import Paragraph from "../text/paragraph";
import Title from "../text/title";
import "twin.macro";
import Quote from "./quote";

const OfferBlock = ({
  title,
  p1,
  p2,
  quote = { quote: "", quoteAuthor: "" },
  quoteEnabled = true,
}) => {
  return (
    <div>
      <Title>{title}</Title>
      <Paragraph>{p1}</Paragraph>
      {quoteEnabled && <Quote quote={quote} />}
      <Paragraph>{p2}</Paragraph>
    </div>
  );
};

export default OfferBlock;
