import React from "react";
import Layout, { PageWrapper } from "../../components/layout";
import { PageSection } from "../../styles/index/_components";
import Pride from "../../images/vectors/undraw_pride_y0te.svg";
import "twin.macro";
import OfferSiteBlock from "../../components/blocks/offerSiteBlock";
import PricesBlock from "../../components/blocks/pricesBlock";
import { Link } from "gatsby";

const Title = "Pomoc seksuologiczna";

const Desc =
  "Sfera seksualna człowieka jest niezwykle złożona. Na jej wieloaspektowość wpływają różne czynniki, zarówno natury psychologicznej, biologicznej jak i społecznej. Niepokoi Cię sposób przeżywania swojej seksualności? Cierpisz z powodu problemów w zakresie tożsamości płciowej czy orientacji psychoseksualnej? Martwisz się intensywnością czy rodzajem swoich zachowań seksualnych, czy może Twoje fantazje seksualne są dla Ciebie powodem niepokoju lub wstydu? Jeśli tak, to trafiłeś w dobre miejsce, pomożemy Ci zrozumieć Twój problem i zajmiemy się jego rozwiązaniem.";

const Quote =
  "„Popęd seksualny jest taką właściwością ludzkiego organizmu, której nie można zaspokoić w inny sposób, jak tylko poprzez zachowania seksualne.”";
const QuoteAuthor = "A. Depko";

const CTAText =
  "W momencie, gdy zdecydujesz się na swoją pierwszą konsultację seksuologiczną, to na pierwszych spotkaniach zbierzemy od Ciebie wywiad i omówimy z Tobą problem z jakim się zgłaszasz. Następnie ustalimy czy będziesz potrzebować dalszej pomocy – jeśli tak, to jaki rodzaj pomocy to będzie. Zapraszamy do kontaktu.";

const Prices = [
  "Konsultacja indywidualna seksuologiczna: 150 zł",
  "Konsultacja pary: 200 zł za 80 minut",
];

const PomocSeksuologiczna = () => {
  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <OfferSiteBlock
            quoteAuthor={QuoteAuthor}
            quote={Quote}
            title={Title}
            CTAText={CTAText}
            desc={Desc}
            prices={Prices}
            Image={Pride}
          />

          <PricesBlock
            title="Konsultacja indywidualna seksuologiczna"
            headlines={["Osoba", "Cena", "Czas"]}
            fills={[
              { name: "Justyna Barton", price: "170 zł", time: "50 minut" },
              { name: "Maciej Schmidt", price: "200 zł", time: "50 minut" },
                { name: "Julianna Graczyk", price: "200 zł", time: "50 minut" },
            ]}
          />
          <div tw="flex items-center justify-center p-8">
            <Link
              to={"/kontakt"}
              tw="transform animate-pulse  p-6  text-xl md:text-3xl  border-4 border-four rounded-lg text-four hover:scale-110"
            >
              Umów się na wizytę
            </Link>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default PomocSeksuologiczna;
