import React from "react";
import Pricing from "../text/pricing";
import OfferBlock from "./offerBlock";
import "twin.macro";

const OfferSiteBlock = ({
  prices,
  title,
  desc,
  CTAText,
  quote,
  quoteAuthor,
  Image,
}) => {
  return (
    <div tw="grid grid-flow-row md:grid-flow-col md:grid-cols-2">
      <div tw="flex flex-col justify-between w-full">
        <Image tw="w-full" />
      </div>
      <OfferBlock
        title={title}
        pricing={prices}
        p1={desc}
        p2={CTAText}
        quote={{ quote: quote, quoteAuthor: quoteAuthor }}
      />
    </div>
  );
};

export default OfferSiteBlock;
