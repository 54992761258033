import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import Quotes from "/src/images/vectors/quotes.svg";

export const CytatInset = styled.div`
  position: relative;
  max-width: 1024px;
  padding: ${(props) => (props.sticky ? "4em" : "2em")};

  @media (max-width: 1024px) {
    text-align: justify;
    padding: 2em 1em;
  }
`;

export const Cytat = styled.div`
  margin: ${(props) => (props.sticky ? "2em 0em 2em 25vw" : "2em")};

  ${tw`bg-gray-100 border-b-2 border-two text-xl text-temp-50`}
  height: auto;

  /*&::before {
    display: block;
    position: relative;
    content: '"';
    font-size: 8em;
    ${tw`font-times`}
    left: -0.4ch;
    top: 0.3ch;
  }*/

  @media (max-width: 1024px) {
    width: calc(100% - 1.5em);
    margin-left: 1.5em;
  }
`;

export const QuotesStyled = styled(Quotes)`
  width: 3em;
  height: 3em;
  position: absolute;
  top: -1em;
  left: -1em;
`;

const Quote = ({ quote, sticky = false }) => {
  return (
    <div tw="py-4 md:py-6">
      <Cytat sticky={sticky}>
        <CytatInset sticky={sticky}>
          <QuotesStyled />
          {quote.quote}
          <span tw="block text-right w-full mt-2">{quote.quoteAuthor}</span>
        </CytatInset>
      </Cytat>
    </div>
  );
};

export default Quote;
