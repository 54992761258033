import React from "react";
import "twin.macro";

const Pricing = ({ pricing = [] }) => {
  return (
    <div tw="pb-6">
      {pricing.map((price) => {
        return <div tw="text-2xl font-medium">{price}</div>;
      })}
    </div>
  );
};

export default Pricing;
